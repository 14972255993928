import {
  useEffect, useState, useRef,
} from 'react';
import { useParams } from 'react-router-dom';
import Select from 'react-select';

import trapWrapper from './trapWrapper';
import sentinelClient from './sentinelClient';

import {
  ALLOW_DATA_DOWNLOAD,
  PERFORM_RAT_DETECTION,
  REQUIRED_EVENT_COUNT,
} from '../../config';

const STEP_START_LOCAL = 'StartLocal';
const STEP_STOP_LOCAL = 'StopLocal';
const STEP_START_REMOTE = 'StartRemote';
const STEP_STOP_REMOTE = 'StopRemote';
const STEP_RESET = 'Reset';

const programs = [
  {
    value: 'AnyDesk',
    label: 'AnyDesk',
  },
  {
    value: 'Zoom',
    label: 'Zoom',
  },
  {
    value: 'GoogleMeet',
    label: 'Google Meet',
  },
  {
    value: 'MsTeams',
    label: 'Microsoft Teams',
  },
  {
    value: 'Skype',
    label: 'Skype',
  },
  {
    value: 'Citrix',
    label: 'Citrix',
  },
  {
    value: 'Other',
    label: 'Other',
  },
];

const DataCollection = () => {
  const params = useParams();
  const defaultProgram = programs
    .find((c) => c.value.toLowerCase() === params.program)?.value;

  let defaultStep = 0;
  if (params.step === 'remote' || params.program === 'remote') {
    defaultStep = 2;
  }

  const [eventCount, setEventCount] = useState(0);
  const [additionalInfo, setAdditionalInfo] = useState(undefined);
  const [step, setStep] = useState(defaultStep);
  const [program, setProgram] = useState(defaultProgram);
  const [ratResult, setRatResult] = useState({});
  const [showRatResult, setShowRatResult] = useState(false);
  const [detectRatInProgress, setDetectRatInProgress] = useState(false);
  const [collectedEvents, setCollectedEvents] = useState(null);

  const selectRef = useRef(null);

  const steps = [{
    key: STEP_START_LOCAL,
    label: 'Start local collection',
  }, {
    key: STEP_STOP_LOCAL,
    label: 'Stop local collection',
  }, {
    key: STEP_START_REMOTE,
    label: 'Start remote collection',
  }, {
    key: STEP_STOP_REMOTE,
    label: 'Stop remote collection',
  }, {
    key: STEP_RESET,
    label: 'Perform a new data collection',
  }];

  const abort = () => {
    trapWrapper.sendCustomData({
      type: 'abort',
    });
    trapWrapper.reset();
    setStep(0);
    setProgram('');
    setEventCount(0);
    setAdditionalInfo('');
    setShowRatResult(false);
    setCollectedEvents(null);
    selectRef.current.clearValue();
  };

  const isStepDisabled = () => {
    const stepKey = steps[step].key;

    switch (stepKey) {
      case STEP_START_LOCAL:
      case STEP_START_REMOTE:
        return program === undefined;
      case STEP_STOP_LOCAL:
      case STEP_STOP_REMOTE:
        return eventCount < REQUIRED_EVENT_COUNT;
      default:
        return false;
    }
  };

  const sendStepData = () => {
    trapWrapper.sendCustomData({
      type: 'step',
      step: steps[step].key,
      program,
      additionalInfo,
    });
  };

  const detectRat = async () => {
    if (PERFORM_RAT_DETECTION) {
      try {
        setDetectRatInProgress(true);
        setRatResult(await sentinelClient.detectRat(collectedEvents));
        setShowRatResult(true);
      } catch (error) {
        setRatResult({
          result: error.message,
        });
        setShowRatResult(true);
      } finally {
        setDetectRatInProgress(false);
      }
    }
  };

  const downloadCollectedEvents = () => {
    const a = document.createElement('a');
    const { sessionId, streamId } = trapWrapper.infoJSON;

    let stepStr = '';
    switch (steps[step - 1].key) {
      case STEP_START_LOCAL:
      case STEP_STOP_LOCAL:
        stepStr = 'local';
        break;
      case STEP_START_REMOTE:
      case STEP_STOP_REMOTE:
        stepStr = 'remote';
        break;
      default:
        stepStr = '';
        break;
    }

    a.href = URL.createObjectURL(
      new Blob(
        [JSON.stringify(collectedEvents)],
        { type: 'application/json' },
      ),
    );
    a.setAttribute('download', `${sessionId}-${streamId}-${stepStr}.json`);
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };

  const nextStep = () => {
    const stepKey = steps[step].key;
    let events = null;

    switch (stepKey) {
      case STEP_START_LOCAL:
      case STEP_START_REMOTE:
        setShowRatResult(false);
        setCollectedEvents(null);
        trapWrapper.start();
        sendStepData();
        break;
      case STEP_STOP_LOCAL:
      case STEP_STOP_REMOTE:
        sendStepData();
        trapWrapper.stop();
        events = trapWrapper.flushCollectedEvents();
        setCollectedEvents(events);
        break;
      case STEP_RESET:
        abort();
        return;
      default:
        // do nothing;
        break;
    }

    setStep((prevStep) => prevStep + 1);
  };

  const getResultString = () => {
    if (ratResult.result === 'ok') {
      return ratResult.outcome;
    }
    return ratResult.result;
  };

  useEffect(() => {
    const onEventCountChanged = ({ eventCount: e }) => { setEventCount(e); };
    trapWrapper.on('eventsCollected', onEventCountChanged);
    return () => {
      trapWrapper.off('eventsCollected', onEventCountChanged);
    };
  }, []);

  const showDownloadButton = () => collectedEvents != null
    && ALLOW_DATA_DOWNLOAD;

  const showDetectRatButton = () => collectedEvents != null
    && PERFORM_RAT_DETECTION;

  return (
    <div>
      <label className="form-control">
        <div className="label">
          <span className="label-text">Program</span>
        </div>
        <Select
          className="my-react-select-container"
          classNamePrefix="my-react-select"
          isClearable
          isSearchable
          options={programs}
          value={programs.find((c) => c.value === program)}
          ref={selectRef}
          onChange={(a) => setProgram(a?.value)}
        />
      </label>
      <label className="form-control mb-8">
        <div className="label">
          <span className="label-text">Additional info</span>
        </div>
        <input
          placeholder="Additional info"
          className="input input-bordered"
          autoComplete="off"
          value={additionalInfo}
          onChange={(a) => setAdditionalInfo(a?.value)}
          disabled={step === steps.length - 1}
        />
      </label>
      <label className="form-control mb-8">
        <div className="label">
          <span className="label-text">Data collection progress</span>
        </div>
        <progress
          className="progress progress-primary w-56"
          value={eventCount}
          max={REQUIRED_EVENT_COUNT}
        />
      </label>
      <label className="form-control">
        {showDownloadButton() && (
          <button
            id='downloadEvents'
            className="btn btn-primary mb-8"
            onClick={downloadCollectedEvents}
          >
            Download collected data
          </button>
        )}
        {showDetectRatButton() && (
          <button
            id='detectRat'
            className="btn btn-primary mb-8"
            onClick={detectRat}
            disabled={detectRatInProgress}
          >
            Peform RAT detection
          </button>
        )}
        <button
          id='nextStep'
          className="btn btn-primary mb-8"
          onClick={nextStep}
          disabled={isStepDisabled()}
        >
          {steps[step].label}
        </button>
        <button
          className="btn btn-secondary mb-16"
          onClick={abort}
          disabled={step === 0}
        >
          Abort
        </button>
      </label>
      <dialog
        id="rdd_modal"
        className={`modal ${showRatResult ? 'modal-open' : ''}`}
      >
        <div className="modal-box">
          <h3 className="text-lg font-bold">
            {`RAT detection result: ${getResultString()}`}
          </h3>
          <div className="modal-action">
            <form method="dialog">
              <button className="btn" onClick={() => setShowRatResult(false)}>
                Close
              </button>
            </form>
          </div>
        </div>
      </dialog>
      {detectRatInProgress && (
        <div className="modal modal-open">
          <div className="modal-box flex content-center">
            <div className="loading loading-spinner loading-lg flex-col"/>
            <div className="text-lg font-bold flex-col self-center ml-8">
              Performing detection
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default DataCollection;
