import DataCollection from '../DataCollection/index';
import Logo from '../Logo/index';

const Menu = () => (
  <div className="w-1/4 flex justify-between items-center flex-col">
    <Logo />
    <div>
      <DataCollection />
    </div>
    <p className="text-slate-400">
      © 2024, Cursor Insight
    </p>
  </div>
);

export default Menu;
